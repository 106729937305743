<template>
  <base-view>
    <view-section>
      <h1 class="mb-8 text-4xl font-semibold">Search Results</h1>
      <div v-if="!articles">Loading...</div>
      <div v-else>
        <div class="overflow-hidden rounded-lg shadow-lg">
          <p class="px-4 py-2 m-0 text-white bg-primary">
            Showing results {{ 10 * (currentPage - 1) + 1 }} -
            {{ currentPage === numPages ? results.length : 10 * currentPage }}
            of {{ results.length }} for "<i>{{ query }}</i> "
          </p>
          <router-link
            v-for="(result, index) in slicedResults"
            :key="index"
            class="block p-4 border-0 border-b border-neutral border-solid last:border-b-0 hover:bg-neutral-light active:bg-neutral"
            :to="'/support/articles/' + result.item.id"
          >
            {{ result.item.title }}
          </router-link>
          <div class="p-2 text-center text-white bg-neutral-900">
            <template v-for="page in numPages">
              <span
                :key="'page-' + page"
                class="inline-block mx-2 my-0 cursor-pointer select-none hover:text-primary-light"
                :class="{ 'text-primary-light': currentPage === page }"
                @click="currentPage = page"
                v-if="
                  page === 1 ||
                    page === numPages ||
                    (page >= currentPage - 2 && page <= currentPage + 2)
                "
              >
                {{ page }}
              </span>
              <span
                :key="page"
                class="mx-2 my-0 select-none"
                v-else-if="
                  (currentPage - 3 > 1 && page === 2) ||
                    (currentPage + 3 < numPages && page === numPages - 1)
                "
                >...</span
              >
            </template>
          </div>
        </div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

import { mapGetters } from "vuex";
import Fuse from "fuse.js";

export default {
  components: {
    BaseView,
    ViewSection
  },
  data() {
    return {
      query: this.$route.params.query,
      currentPage: 1
    };
  },
  created() {},
  computed: {
    ...mapGetters(["allZendeskData"]),
    articles() {
      return this.$store.getters.allZendeskData.articles;
    },
    results() {
      return this.search(this.query);
    },
    numPages() {
      return Math.ceil(this.results.length / 10);
    },
    slicedResults() {
      return this.results.slice(
        10 * (this.currentPage - 1),
        10 * this.currentPage
      );
    }
  },
  methods: {
    search(value) {
      if (value !== "" && this.articles) {
        var fuse = new Fuse(this.articles, {
          keys: ["title", "body"]
        });

        return fuse.search(value);
      } else {
        return [];
      }
    }
  },
  watch: {
    $route(to, from) {
      this.query = this.$route.params.query;
    }
  }
};
</script>
